













import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Home extends Vue {
  public get locale(): string {
    return this.$i18n.locale;
  }
}
