



























import { Vue, Component, Prop } from "vue-property-decorator";
import { getTenant } from "@/utils";
import { Tenant } from "@/models/userModels";

@Component
export default class FrontDeskCard extends Vue {
  @Prop({ default: () => {} }) public data!: any;
  public tenant: Tenant = getTenant();
  public created() {
    console.log(this.data)
  }
  public redirectToUrl(url: string, e: any): void {
    if (this.data.auth === "all") {
      window.open(this.data.baseUrl, "_blank");
    } else if (
      this.data.authorityModules &&
      this.data.authorityModules.length > 0
    ) {
      window.location.href = `${this.data.baseUrl}${url}?sso=${this.tenant.key}`;
    } else {
      this.$message.error(this.$t("tip.permissionErr") as string);
    }
  }
}
