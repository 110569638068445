































































































































import { Component, Vue } from "vue-property-decorator";
import {
  HrController,
  UserOptLogController,
  UserController,
} from "@/services/request.service";
import ConfirmModal from "@/components/ConfirmModal.vue";
import UploadModal from "@/components/UploadModal.vue";
import AdminManage from "./AdminManage.vue";
import Counter from "@/components/Counter.vue";
import { getFormId } from "@/configs/formMap";
import RelatedApps from "./RelatedApps.vue";
import { getUser } from "@/utils/index";
import { CreateElement, VNode } from "vue";
import { FormApi } from "@/apis/formApi";
import { UserApi } from "@/apis/userApi";
import OrganizationTree from "./OrganizationTree.vue";

@Component({
  components: {
    Counter,
    RelatedApps,
    UploadModal,
    ConfirmModal,
    AdminManage,
    OrganizationTree,
  },
})
export default class TeacherAccMgt extends Vue {
  public title = "";
  public searchWord = "";
  public content: string | VNode = "";
  public visOfImport: boolean = false;
  public loading: boolean = false;
  public visOfRelatedApps: boolean = false;
  public visibleAdminModel: boolean = false;
  public selectedRowKeys = [];
  public data = [];
  public operateColumns = [];
  public confirmType: string = "";
  public show: boolean = false;
  public info: any = {};
  public selectedNodes: Array<any> = [];
  public getFormId: any = getFormId;
  public confirmOK: any = () => {};
  public syncLoading: boolean = false;
  public name: any = "";
  public enName: any = "";
  public email: any = "";
  public employeeType: any = "";
  public dataSource: any = [];
  public backData: any = {};
  public backList: any = {};
  public isDisabled: boolean = false;
  public addForm: any = {
    name: this.name,
    enName: this.enName,
    email: this.email,
    employeeType: this.employeeType,
  };
  public outsideStaffDepartments = [
    "607653e5ef5a00004c005573",
    "607653edef5a00004c005574",
    "609cc14566660000f6005fd9",
  ];
  public confirmLoading: boolean = false;

  public get id() {
    return getUser() ? getUser().userId : "";
  }

  public get filter() {
    const matches = [];
    // matches.push({
    //   name: 'hris_status',
    //   method: 'in',
    //   value: [
    //     '在职In service',
    //     '待入职To be employed',
    //     '待离职Leaving office',
    //     '非hr人员',
    //   ],
    // });
    if (this.searchWord) {
      matches.push({
        name: "",
        method: "orChild",
        value: [
          {
            name: "en_name",
            type: "string",
            method: "regex",
            value: this.searchWord,
          },
          {
            name: "name",
            type: "string",
            method: "regex",
            value: this.searchWord,
          },
        ],
      });
    }
    if (this.selectedNodes.length && this.selectedNodes[0]) {
      matches.push({
        name: "department",
        method: "in",
        value: this.selectedNodes,
        type: "string",
      });
    }
    return matches;
  }

  public getData(): void {
    if (this.$refs.accountTable) {
      (this.$refs.accountTable as any).getList();
    }
  }
  public handleOk() {
    const form = this.$refs.addForm as any;
    form.validate((valid: boolean) => {
      if (valid) {
        if (JSON.stringify(this.info) === "{}") {
          const request: any = {
            authId: getFormId("outsider"),
            data: {
              name: this.addForm.name,
              en_name: this.addForm.enName,
              email: this.addForm.email,
              employee_type: this.employeeType,
              user_id: this.id,
            },
          };
          UserController.addName(request).then((res: any) => {
            this.confirmLoading = true;

            setTimeout(() => {
              this.show = false;
              this.confirmLoading = false;
              this.isDisabled = false;
            this.$message.success(this.$tc("common.saveSuccess"));
            }, 1000);
            this.getData();
          });
        } else {
          const request: any = {
            authId: getFormId("outsider"),
            data: {
              name: this.addForm.name,
              en_name: this.addForm.enName,
              email: this.addForm.email,
              employee_type: this.employeeType,
              user_id: this.id,
              _id: this.info._id,
            },
          };
          UserController.addName(request).then((res: any) => {
            this.confirmLoading = true;
            setTimeout(() => {
              this.show = false;
              this.confirmLoading = false;
              this.isDisabled = false;
            this.$message.success(this.$tc("common.saveSuccess"));
            }, 1000);
            this.getData();
          });
        }
      }
    });
  }

  public handleCancel() {
    this.show = false;
    this.isDisabled = false;
  }
  public onNameSearch(searchText: any) {
    if (searchText) {
      UserController.requestName({
        searchWord: searchText,
      }).then((res: any) => {
        this.backList = res.data;

        const list = res.data.map((item: any) => {
          return {
            text:
              (item.znName ? item.znName : "") +
              (item.enName ? item.enName : ""),
            value: item.hrStaffId.toString(),
          };
        });
        this.dataSource = list;
        // if (this.$i18n.locale === "zh") {
        //   this.dataSource = res.data;
        // } else {
        //   this.dataSource = res.data.map((item: any) => item.enName);
        // }

        //  email: item.email,
        //     enName: item.enName,
        //     znName: item.znName,
        //     hrStaffId: item.hrStaffId,
        //     personnelType: item.personnelType,
      });
    }
  }
  public nameSelect(value: any) {
    this.isDisabled = true;
    this.backList.map((item: any) => {
      if (item.hrStaffId.toString() === value) {
        this.backData = item;
      }
    });
    this.addForm.email = this.backData.email;
    this.addForm.enName = this.backData.enName;
    this.addForm.name = this.backData.znName;
    // this.addForm.employeeeType = this.backData.personnelType;

    //     enName: item.enName,
    //     znName: item.znName,
    //     hrStaffId: item.hrStaffId,
    //     personnelType: item.personnelType,
  }
  public get locale(): string {
    return this.$i18n.locale;
  }
  public onChange(res: any) {
    // this.dataSource = res.data.enName ;
  }
  public onAutoChange(value: any) {
    if (!value) {
      this.isDisabled = false;
      this.addForm.email = "";
      this.addForm.enName = "";
    }
  }
  public onSelectChange(selectedRowKeys: any, selectedRows: any): void {
    this.selectedRowKeys = selectedRowKeys;
    this.operateColumns = selectedRows;
  }

  public onSearch(text: string) {
    this.searchWord = text.trim();
  }

  public async handleSubmit(values: any) {
    if (JSON.stringify(this.info) !== "{}") {
      await FormApi.editData(getFormId("outsider"), {
        ...values,
        user_id: this.id,
      });
    } else {
      await FormApi.addData(getFormId("outsider"), {
        ...values,
        user_id: this.id,
      });
    }
    this.getData();
    this.$message.success(this.$tc("common.saveSuccess"));
  }

  public showEditVisible(record: any) {
    console.log(record);
    this.show = true;
    this.info = record || {};
    if (JSON.stringify(this.info) === "{}") {
      this.addForm = {
        name: "",
        enName: "",
        email: "",
        employeeType: "",
      };
    } else {
      this.addForm = {
        name: this.info.name,
        enName: this.info.en_name,
        email: this.info.email,
        employeeType: this.info.employee_type,
      };
    }
  }

  public actionRender(h: CreateElement, actions: any) {
    return {
      title: this.$t("common.action"),
      key: "action",
      fixed: "right",
      width: 250,
      customRender: (record: any) => {
        const divider = h("a-divider", { props: { type: "vertical" } });
        const nodes = [
          h(
            "a",
            { on: { click: () => this.changeVisOfRelatedApps(true, record) } },
            this.$t("account.relatedApps") as string
          ),
        ];
        if (record.sso_status === "disable") {
          nodes.push(
            divider,
            h(
              "a",
              { on: { click: () => this.changeStatus("able", record) } },
              this.$t("common.enable") as string
            )
          );
        } else {
          nodes.push(
            divider,
            h(
              "a",
              { on: { click: () => this.changeStatus("disable", record) } },
              this.$t("common.disable") as string
            )
          );
        }
        nodes.push(
          divider,
          h(
            "a",
            { on: { click: () => this.resetPwd(record) } },
            this.$t("account.resetPwd") as string
          )
        );
        if (this.outsideStaffDepartments.includes(record.department)) {
          nodes.splice(
            1,
            0,
            divider,
            h(
              "a",
              { on: { click: () => this.showEditVisible(record) } },
              this.$t("common.edit") as string
            )
          );
        }
        // nodes.push(divider, h('a', { on: { click: () => {} } }, this.$t('common.delete') as string));
        return nodes;
      },
    };
  }

  public changeStatus(type: any, record: any): void {
    this.title = this.$t(`tip.${type}`) as string;
    this.content = this.$t(`tip.${type}Tip`) as string;
    this.confirmType = type;
    if (record) {
      this.operateColumns = [].concat(record);
    }
    this.confirmOK = () => this.getUserUpdateDisable(this.confirmType);
    this.$nextTick(() => {
      if (this.$refs.confirmModal) {
        (this.$refs.confirmModal as ConfirmModal).showConfirm();
      }
    });
  }
  public handleChange(value: any) {
    this.employeeType = value;
  }
  public resetPwd(record: any): void {
    const { email } = record;
    this.title = this.$tc("common.resetPwd");
    const h = this.$createElement;
    this.content = this.resetPwdContent(h, email);
    this.confirmOK = () => this.resetPwdApi(record);
    this.$nextTick(() => {
      if (this.$refs.confirmModal) {
        (this.$refs.confirmModal as ConfirmModal).showConfirm();
      }
    });
  }
  public get rules() {
    return {
      name: [
        {
          required: true,
          message: this.$tc("nameInvaild"),
          max: 50,
          min: 1,
          whitespace: true,
        },
      ],
      enName: [
        {
          message: this.$tc("enNameInvaild"),
          pattern: /^[a-zA-Z\s]{1,50}$/,
          whitespace: true,
        },
      ],
      email: [
        { required: true, message: this.$tc("emailInvaild"), type: "email" },
      ],
      employeeType: [{ required: true, message: this.$tc("pleaseSelect") }],
    };
  }
  public resetPwdContent(h: CreateElement, email: string) {
    return h("div", [
      h(
        "p",
        { style: { marginBottom: "8px" } },
        `${this.$t("personnel.email")}：${email}`
      ),
      h(
        "p",
        { style: { margin: 0 } },
        `${this.$t("personnel.password")}：school2020`
      ),
    ]);
  }

  public resetPwdApi(record: any) {
    UserApi.resetPassword(record._id).then(() => {
      this.$message.success(this.$tc("common.actionSuccess"));
      if (record._id === this.id) {
        window.location.href = "/api/logout";
      }
    });
  }

  public changeVisOfImport(flag: boolean, record?: any): void {
    this.visOfImport = flag;
  }

  public changeVisOfRelatedApps(flag: boolean, record?: any) {
    this.visOfRelatedApps = flag;
    if (record) {
      this.operateColumns = [].concat(record);
    }
  }

  public getUserUpdateDisable(status: string, cb?: any): void {
    if (this.operateColumns.length > 0) {
      const tmp = { sso_status: status };
      const data = this.operateColumns.map((item: any) => {
        return { ...item, ...tmp };
      });
      FormApi.batchEditData(getFormId("accountMgt"), data)
        .then((res) => {
          this.$message.success(this.$tc("tip.updateSuccess"));
          this.getData();
        })
        .finally(() => {
          if (typeof cb === "function") {
            cb();
          }
        });
    }
  }

  public downloadExcel() {
    this.loading = true;
    (this.$refs.accountTable as any)?.exportExcel().then(() => {
      this.loading = false;
      UserOptLogController.addUserOptLog({
        optType: "6",
        content: "导出excel",
      });
    });
  }

  public treeChange(nodes: Array<any>) {
    this.selectedNodes = nodes;
    (this.$refs?.table as any)?.clearAllFilter();
    this.searchWord = "";
  }

  public hrSync() {
    this.syncLoading = true;
    HrController.hrSync()
      .then(() => {
        this.$message.success(this.$tc("account.hrSyncSuccess"), 5);
      })
      .finally(() => {
        this.syncLoading = false;
      });
  }
}
