





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormApi } from '@/apis/formApi';
import { message } from 'ant-design-vue';
import { getCurrentLang } from '@/utils';
import { rootStore } from '@/stores/rootStore';

@Component
export default class EditBlock extends Vue {
  @Prop()
  public name!: string;
  @Prop()
  public id!: string;
  @Prop()
  public type!: string;
  @Prop({
    default: true,
  })
  public editable!: boolean;

  public json = '';
  public data: any = [];
  public show = false;
  public get filter() {
    return [{ name: 'user_id', method: 'eq', value: [this.id] }];
  }

  public get title() {
    if (!this.json) {
      return '';
    }
    return JSON.parse(this.json)[getCurrentLang() === 'zh' ? 'name' : 'enName'];
  }

  public get opts() {
    return rootStore.getFormOptByFormId(this.name);
  }

  public get actions() {
    const opts = this.opts.slice();
    let index = opts.indexOf('add');
    if (index !== -1) {
      opts.splice(index, 1);
    }
    index = opts.indexOf('filter');
    if (index !== -1) {
      opts.splice(index, 1);
    }
    return opts;
  }

  public created() {
    this.getForm();
    if (this.type !== 'table') {
      this.getData();
    }
  }

  public getList() {
    const table: any = this.$refs.table;
    if (table && table.getList) {
      table.getList();
    }
  }

  public getData() {
    FormApi.getPage({
      authId: this.name,
      matches: this.filter,
    }).then(data => {
      this.data = data.items[0];
    });
  }

  public getForm() {
    FormApi.getForm(this.name).then(data => {
      this.json = JSON.stringify(data);
    });
  }

  public async handleSubmit(values: any) {
    if (this.type === 'table') {
      await FormApi.addData(this.name, { ...values, user_id: this.id });
      this.getList();
    } else {
      await FormApi.editData(this.name, { ...values, user_id: this.id });
      this.getData();
    }
    message.success(this.$tc('common.saveSuccess'));
  }
}
