





























import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { OtherApi } from '@/apis/otherApi';
import { RootStore } from '@/stores/rootStore';
import { getCurrentLang } from '@/utils';

const getParentKey = (key: string, tree: any): any => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

function filterTreeData(list: any[], search: string) {
  const out: any[] = [];
  list.forEach((item) => {
    if (typeof item.title === 'string' && item.title.includes(search)) {
      out.push(item);
    } else if (item.children) {
      const children = filterTreeData(item.children, search);
      if (children.length > 0) {
        out.push({
          ...item,
          children,
        });
      }
    }
  });
  return out;
}

@Component
export default class OrganizationTree extends Vue {
  @Inject()
  public rootStore!: RootStore;
  public autoExpandParent = true;
  @Prop({ default: true })
  public showNum!: boolean;
  public expandedKeys: string[] = [];
  public treeSearch = '';
  public selectedNode = '';
  public nativeList: any[] = [];

  public get list() {
    return (
      this.nativeList?.map((item) => ({
        ...item,
        title: getCurrentLang() === 'zh' ? item.name : item.enName,
        key: item._id,
        scopedSlots: { title: 'title' },
      })) || []
    );
  }

  public get treeData() {
    const treeData = this.list2Tree(this.list || []);
    if (treeData.length && !this.showNum && !this.selectedNode) {
      this.onSelect([], { node: { eventKey: treeData[0].key } });
    }
    return treeData;
  }

  public get factTreeData() {
    let { treeData } = this;
    if (this.showNum) {
      treeData = [
        {
          title: this.$createElement('div', { class: 'home' }, [
            this.$createElement('a-icon', {
              props: { component: require('@/assets/home.svg') },
            }),
            getCurrentLang() === 'zh'
              ? this.rootStore.currentTenant.tenantName
              : this.rootStore.currentTenant.tenantEnName,
          ]),
          key: '',
        },
        ...treeData,
      ];
    }
    if (this.treeSearch) {
      return filterTreeData(treeData, this.treeSearch);
    }
    return treeData;
  }

  public list2Tree(
    list: any[],
    id = '_id',
    parentId = 'parentId',
    cp?: string
  ): any[] {
    const list1: any[] = [];
    const list2: any[] = [];
    list.forEach((item) => {
      if ((!cp && !item[parentId]) || (cp && item[parentId] === cp)) {
        list1.push(item);
      } else {
        list2.push(item);
      }
    });
    return list1.map((item) => {
      const children = this.list2Tree(list2, id, parentId, item[id]);
      if (children.length) {
        return {
          ...item,
          children,
        };
      }
      return item;
    });
  }

  public created() {
    OtherApi.organize().then((res) => {
      this.nativeList = res || [];
      const filteredList = this.nativeList.filter((i) => !i.parentId);
      if (filteredList.length >= 1) {
        this.expandedKeys = [filteredList[0]._id];
      }
    });
  }

  public onExpand(expandedKeys: string[]) {
    this.expandedKeys = expandedKeys;
    this.autoExpandParent = false;
  }

  public onSelect(nodeList: string[], e: any) {
    const value = e.node.eventKey;
    this.selectedNode = value;
    const emitValue = [value];
    let findList = [value];
    do {
      findList = this.list
        .filter((obj) => findList.includes(obj.parentId))
        .map((f) => f.key);
      if (findList.length) {
        emitValue.push(...findList);
      }
    } while (findList.length);
    this.$emit('change', emitValue);
  }

  public onChange(e: any) {
    const value = e.target.value.trim();
    this.treeSearch = value;
    this.autoExpandParent = !!value;
    if (value) {
      this.expandedKeys = this.list
        .map((item) => {
          if (
            typeof item.title === 'string' &&
            item.title.indexOf(value) > -1
          ) {
            return getParentKey(item.key, this.treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
    } else {
      this.expandedKeys = [];
    }
  }
}
