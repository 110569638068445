














import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';

@Component
export default class Counter extends Vue {
  @Prop({ default: 0 }) public total!: number;
  @Prop({ default: 0 }) public selectedNum!: number;
  @Prop({ default: true }) public showSelect!: boolean;

  public clearSelectedItem(): void {
    this.$emit('clear');
  }
}
