























import { Component, Vue, Model, Emit } from 'vue-property-decorator';
import { UserApi } from '@/apis/userApi';

@Component
export default class UpdatePassword extends Vue {
  @Model('change')
  public show!: string;

  public form: any = {
    password: '',
    newPassword: '',
    checkPassword: '',
  };

  public get rules() {
    return {
      password: [{ required: true, min: 1, max: 50, message: this.$t('login.passwordError') }],
      newPassword: [{ required: true, validator: this.validatePass }],
      checkPassword: [{ required: true, validator: this.validatePass2 }],
    };
  }

  public validatePass(rule: any, value: any, callback: any) {
    if (value === '' || value.length > 50) {
      callback(new Error(this.$tc('login.passwordError')));
    } else {
      if (this.form.newPassword !== '') {
        (this.$refs.ruleForm as any).validateField('checkPassword');
      }
      callback();
    }
  }

  public validatePass2(rule: any, value: any, callback: any) {
    if (value === '' || value.length > 50) {
      callback(new Error(this.$tc('login.passwordError')));
    } else if (value !== this.form.newPassword) {
      callback(new Error(this.$tc('personnel.passwordNotMatch')));
    } else {
      callback();
    }
  }

  public savePassword() {
    const form: any = this.$refs.ruleForm;
    form.validate((valid: boolean) => {
      if (valid) {
        const { password, newPassword, checkPassword } = this.form;
        UserApi.modifyPassword(password, newPassword, checkPassword).then(() => {
          this.$message.success(this.$tc('common.actionSuccess'));
          this.cancel();
          window.location.href = '/api/logout';
        });
      } else {
        return false;
      }
    });
  }

  @Emit('change')
  public cancel() {
    return false;
  }
}
