

































import { Component, Vue } from "vue-property-decorator";
import { PortalController } from "@/services/request.service";
import BackendCard from "./BackendCard.vue";
import FrontDeskCard from "./FrontDeskCard.vue";
import config from "./constant";

@Component({
  components: {
    // Counter,
    // BulletinList,
    BackendCard,
    FrontDeskCard,
  },
})
export default class Home extends Vue {
  public authorityList: any = {};
  public list = [];
  public unreadNum = 0;

  public get frontDeskList() {
    const list: any = [];
    config.frontDeskList.forEach((item) => {
      const sysList = item.systemList;
      const tmp = [];
      const system = this.authorityList[item.key];
      const modules =
        system && system.authorityModules ? system.authorityModules : [];
      for (const sys of sysList) {
        if (modules.includes(sys.key)) {
          tmp.push(sys);
        }
      }
      if (system && system.hasOwnProperty("authorityModules")) {
        list.push({ ...item, systemList: tmp, authorityModules: modules });
      } else if (item.key == "EnSEED") {
        list.push({ ...item, auth: "all" });
      }
    });
    return list;
  }

  public get otherList() {
    const list: any = [];
    config.otherList.forEach((item) => {
      list.push({ ...item, systemList: [], authorityModules: [] });
    });
    return list;
  }

  public get backendList() {
    const list: any = [];
    config.backendList.forEach((item) => {
      const system = this.authorityList[item.key];
      const modules =
        system && system.authorityModules ? system.authorityModules : [];
      if (system && system.hasOwnProperty("authorityModules")) {
        list.push({ ...item, authorityModules: modules });
      }
    });
    return list;
  }

  public created(): void {
    this.getRoleList();
    // this.getBulletinList();
    // this.getUnReadNum();
  }

  public getRoleList(): any {
    PortalController.portalAuthorities().then((res) => {
      this.authorityList = res && res.data ? res.data : {};
    });
  }
}
