







import { Vue, Component, Prop } from 'vue-property-decorator';
import { getTenant } from '@/utils';
import { Tenant } from '@/models/userModels';

@Component
export default class BackendCard extends Vue {
  @Prop() public data!: any;
  public tenant: Tenant = getTenant();

  public redirectToUrl(): void {
    if (this.data.authorityModules && this.data.authorityModules.length > 0) {
      window.location.href = `${this.data.baseUrl}?sso=${this.tenant.key}`;
    } else {
      this.$message.error(this.$t('tip.permissionErr') as string);
    }
  }
}
