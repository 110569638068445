












































import { Component, Vue, Watch, Emit, Model } from 'vue-property-decorator';
import { UserController } from '@/services/request.service';
import { rootStore } from '@/stores/rootStore';

@Component
export default class AdminManage extends Vue {
  @Model('change', { default: true }) public visible!: boolean;
  public loading: boolean = false;
  public selectedItems: any = [];
  public userList: any = [];
  public adminList: any = [{ _id: 1 }, { _id: 2 }];

  public get statusList() {
    return [
      {
        label: this.$t('account.able'),
        value: 'able',
      },
      {
        label: this.$t('account.disable'),
        value: 'disable',
      },
    ];
  }

  public get rules() {
    return {
      status: [
        {
          required: true,
          message: this.$t('tip.fieldRequired', {
            field: this.$t('account.status'),
          }),
          trigger: 'blur',
        },
      ],
    };
  }

  public get userInfo() {
    return rootStore.user;
  }

  @Watch('visible', { immediate: true })
  public resetData(): void {
    if (this.visible) {
      this.selectedItems = [];
      this.getData();
    }
  }

  public getData() {
    UserController.getUserGroupsByAdmin().then((res) => {
      this.userList = res.data.noAdmins;
      this.adminList = res.data.admins;
    });
  }

  public handleClose(removedTag: any) {
    const tags = this.adminList.filter(
      (adminList: any) => adminList !== removedTag
    );
    this.adminList = tags;
  }

  public handleOk(): void {
    let ids = [
      ...this.selectedItems,
      ...this.adminList.map((item: any) => item._id),
    ];
    UserController.setAdmin(ids).then(() => {
      this.$message.success(this.$tc('common.actionSuccess'));
      this.handleCancel();
    });
  }

  @Emit('change')
  public handleCancel() {
    return false;
  }
}
