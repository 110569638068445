


import { Component, Vue, Prop } from 'vue-property-decorator';
import { VNode } from 'vue';

@Component
export default class ConfirmModal extends Vue {
  @Prop({ default: '' }) public title!: string;
  @Prop({ default: '' }) public content!: string | VNode;
  public loading: boolean = false;

  public showConfirm() {
    this.$confirm({
      title: this.title,
      content: this.content,
      okButtonProps: { loading: this.loading } as any,
      onOk: () => {
        this.loading = true;
        this.$emit('ok', () => {
          this.loading = false;
        });
      },
      onCancel() {},
    });
  }
}
