




















import { Component, Vue, Prop } from 'vue-property-decorator';
import ContentLayout from '@/components/layout/ContentLayout.vue';

@Component({
  components: {
    ContentLayout,
  },
})
export default class Log extends Vue {
  public searchWord = '';

  public get filter() {
    const matches = [];
    if (this.searchWord) {
      matches.push({
        name: 'name',
        method: 'regex',
        value: [this.searchWord],
        type: 'string',
      });
    }
    return matches;
  }

  public onSearch(text: string) {
    this.searchWord = text.trim();
  }
}
