




























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class UploadModal extends Vue {
  @Prop({ default: false }) public visible!: boolean;
  @Prop({ default: '' }) public title!: string;
  @Prop({ default: '' }) public tip!: string;
  @Prop({ default: '' }) public uploadLabel!: string;
  @Prop({ default: '' }) public url!: string;
  @Prop({ default: '' }) public downloadUrl!: string;
  @Prop({ default: '' }) public templateUrl!: string;
  public status: string = 'upload';
  public importBatchCode: string = '';
  public failedData: any = {};
  public loading: boolean = false;

  @Watch('visible')
  public reset(): void {
    if (this.visible) {
      this.status = 'upload';
      this.failedData = {};
    }
  }

  public cancel(): void {
    this.$emit('cancel', false);
  }

  public handleChange(info: any) {
    if (info.file.status === 'uploading') {
      this.loading = true;
    } else {
      this.loading = false;
    }
    if (info.file.status === 'done') {
      this.failedData = info.file.response;
      this.importBatchCode = this.failedData.importBatchCode;
      if (this.failedData.failNum) {
        this.status = 'failed';
      } else {
        // this.$message.success(this.$t('fileUploadSuccess', { name: info.file.name }));
        this.$emit('cancel', false);
      }
      this.$emit('update');
    } else if (info.file.status === 'error') {
      this.failedData = info.file.response;
      this.$message.error(this.failedData.error);
    }
  }

  public downloadFile(): void {
    window.open(this.downloadUrl + `?importBatchCode=${this.importBatchCode}`);
  }
}
