













import { Component, Vue, Watch, Prop, Model } from 'vue-property-decorator';
// @ts-ignore
import { saveAs } from 'file-saver';

@Component
export default class ImageUpload extends Vue {
  @Prop({ default: false }) public downloadMode!: boolean;
  @Prop({ default: () => [] }) public data!: any[];
  public MAXFILECOUNT: number = 10;

  public downloadFile(item: any) {
    saveAs(`${process.env.VUE_APP_FILE_DOMAIN}/${item.hash}?attname=${item.name}`, item.name);
  }

  public preview(item: any) {
    window.open(`${process.env.VUE_APP_FILE_DOMAIN}/${item.hash}?attname=${item.name}`, '_blank');
  }
}
