
































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { OtherApi } from '@/apis/otherApi';
import { FormApi } from '@/apis/formApi';
import { getFormId } from '@/configs/formMap';
import { RootStore } from '@/stores/rootStore';
import UpdatePassword from './UpdatePassword.vue';

@Component({
  components: {
    UpdatePassword,
  },
})
export default class HeaderInf extends Vue {
  @Inject()
  public rootStore!: RootStore;
  @Prop()
  public id!: string;
  @Prop()
  public userInfo!: any;
  public organList: any[] = [];
  public authGroupList: any[] = [];
  public visible = false;
  public showPassword: boolean = false;
  public getFormId = getFormId;

  public get department() {
    const { userInfo, organList } = this;
    let out = '';
    if (!userInfo || !userInfo.department) {
      return out;
    }
    const obj1 = organList.find((o) => o._id === userInfo.department);
    if (obj1) {
      out = obj1.name;
      if (obj1.parentId) {
        const obj2 = organList.find((o) => o._id === obj1.parentId);
        if (obj2) {
          out = obj2.name + ' / ' + out;
        }
      }
    }
    return out;
  }

  public created() {
    OtherApi.organize().then((data) => {
      this.organList = data || [];
    });
  }
}
