































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { getFormId } from "@/configs/formMap";
import { FormApi } from "@/apis/formApi";

@Component
export default class RelatedApps extends Vue {
  @Prop({ default: false }) public visible!: boolean;
  @Prop({ default: () => [] }) public data!: any;
  public loading: boolean = false;
  public options: any[] = [];
  public form: any = {
    status: "",
    checkedList: [],
  };

  public get statusList() {
    return [
      {
        label: this.$t("account.able"),
        value: "able",
      },
      {
        label: this.$t("account.disable"),
        value: "disable",
      },
    ];
  }

  public get rules() {
    return {
      status: [
        {
          required: true,
          message: this.$t("tip.fieldRequired", {
            field: this.$t("account.status"),
          }),
          trigger: "blur",
        },
      ],
    };
  }

  @Watch("visible", { immediate: true })
  public resetData(): void {
    if (this.visible) {
      this.form = {
        status: (this.data.length === 1 && this.data[0].sso_status) || "able",
        checkedList: (this.data.length === 1 && this.data[0].apps_auth) || [],
      };
    }
  }

  public mounted(): void {
    this.getData();
  }

  public getData() {
    FormApi.getPage({
      authId: getFormId("appList"),
      matches: [],
    }).then((res) => {
      this.options = res.items.map((item: any) => {
        return {
          label: item.name,
          value: item._id,
        };
      });
    });
  }

  public handleSubmit(): void {
    (this.$refs.ruleForm as any).validate((valid: any) => {
      if (valid) {
        this.loading = true;
        const tmp = { apps_auth: this.form.checkedList };
        const data = this.data.map((item: any) => {
          return { ...item, ...tmp };
        });
        FormApi.batchEditData(getFormId("accountMgt"), data)
          .then((res) => {
            this.$message.success(this.$tc("tip.updateSuccess"));
            this.$emit("getData");
            this.$emit("cancel", false);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        return false;
      }
    });
  }

  public handleOk(): void {
    this.handleSubmit();
  }

  public handleCancel(): void {
    this.$emit("cancel", false);
  }
}
