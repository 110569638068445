















































import { Component, Vue } from 'vue-property-decorator';
import EditBlock from '@/views/personnel/EditBlock.vue';
import HeaderInf from '@/views/personnel/HeaderInf.vue';
import { FormApi } from '@/apis/formApi';
import { getFormId } from '@/configs/formMap';
import { getUser } from '@/utils/index';

@Component({
  components: {
    EditBlock,
    HeaderInf,
  },
})
export default class PersonnelDetail extends Vue {
  public getFormId = getFormId;
  public userInfo: any = {};
  public json = '';

  public get id() {
    return getUser() ? getUser().userId : '';
  }
  public get type() {
    return this.$route.query.type || 'baseInf';
  }
  public onTabChange(key: string) {
    this.$router.replace({ name: 'detail', query: { type: key } });
  }

  public created() {
    this.getData();
  }

  public getData() {
    FormApi.getData(getFormId('accountMgt'), this.id).then((rep) => {
      if (rep) {
        this.userInfo = rep;
      }
    });
  }
}
