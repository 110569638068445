export default {
  backendList: [
    {
      key: 'IntSchoolAdmin',
      name: 'IntSchool管理平台',
      icon: 'intSchool-logo.png',
      baseUrl: process.env.VUE_APP_INTSCHOOL_BANKEND_URL,
    },
  ],
  
  frontDeskList: [
    {
      key: 'IntSchoolTeacher',
      name: 'IntSchool 教师平台',
      icon: 'logo-front2.png',
      bgImg: 'bg3.png',
      activeColor: '#26B889',
      baseUrl: process.env.VUE_APP_INTSCHOOL_URL,
      systemList: [
        {
          key: 'My Class',
          name: 'home',
          url: '/home',
        },
        {
          key: 'Attendance',
          name: 'attendance',
          url: '/attendance/attendanceViewer/class',
        },
        {
          key: 'Diary',
          name: 'daybook',
          url: '/diary',
        },
        {
          key: 'Message',
          name: 'message',
          url: '/message/inbox/normal',
        },
      ],
    },
    {
      key: 'IntApply',
      name: 'IntApply 招生平台',
      icon: 'logo-front3.png',
      bgImg: 'bg2.png',
      activeColor: '#26B889',
      baseUrl: process.env.VUE_APP_INTAPPLY_URL,
      systemList: [
        {
          key: '1',
          name: 'enquiries',
          url: '/enquiry/newEnquiries',
        },
        {
          key: '2',
          name: 'applicants',
          url: '/students/applicant',
        },
        {
          key: '3',
          name: 'message',
          url: '/message/inbox',
        },
      ],
    },
    {
      key: 'EnSEED',
      name: 'EnSEED 智能幼儿园',
      icon: 'logo-front3.png',
      bgImg: 'bg1.png',
      activeColor: '#26B889',
      baseUrl: process.env.VUE_APP_ENSEED_URL,
      systemList: [],
    },
  ],

  otherList: [
    {
      key: 'Parent',
      name: 'IntSchool 家长平台',
      icon: 'logo-front2.png',
      bgImg: 'bg4.png',
      type: 'other',
      auth: 'all',
      baseUrl: 'https://parent.enhertz.com',
    },
    {
      key: 'Student',
      name: 'IntSchool 学生平台',
      icon: 'logo-front2.png',
      bgImg: 'bg4.png',
      type: 'other',
      auth: 'all',
      baseUrl: 'https://student.enhertz.com',
    },
    {
      key: 'apply-bm',
      name: '入学申请',
      icon: 'logo-front3.png',
      bgImg: 'bg4.png',
      type: 'other',
      auth: 'all',
      baseUrl: 'https://bm.enhertz.com',
    }
  ]
};
