import axios from '@/utils/fetch';
import { BaseApi } from '@/apis/baseApi';

export class UserApi extends BaseApi {
  public static modifyPassword(oldPassword: string, newPassword: string, authPassword: string) {
    return axios.put('/api/user/modifyPassword', { oldPassword, newPassword, authPassword }).then(res => res.data);
  }
  public static resetPassword(id: string) {
    return axios.put('/api/user/resetPassword', undefined, { params: { userId: id } }).then(res => res.data);
  }
}
