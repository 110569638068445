



































import { Vue, Component } from 'vue-property-decorator';
import Attachments from '@/components/Attachments.vue';
import moment from 'moment';

@Component({
  components: {
    Attachments,
  },
})
export default class BulletinDetail extends Vue {
  public data = [];
  public moment = moment;
  public domain = process.env.VUE_APP_FILE_DOMAIN;

  public created() {
    this.getData();
  }

  public getData() {
    // FormApi.getData(getFormId("notices"), this.$route.params.id).then((res) => {
    //   this.data = res || {};
    // });
  }
}
